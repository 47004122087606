import React from "react";
import { Link } from "react-router-dom";

const HeroOne = () => {
  return (
    <>
      {/* Hero area start */}
      <section className="hero__area">
        <div className="hero__inner">
          <div className="hero__top">
            <h1 className="hero__title cxufadeUp">
              Connecting People
              <br />{" "}
              <Link className="popup-link" to="">
                {" "}
                <span>
                  <i className="fa-solid fa-play" />
                </span>
              </Link>{" "}
              World Wide
            </h1>
            <div className="hero__contact cxufadeUp2">
              <Link to="/contact">
                <span>
                  <i className="fa-solid fa-arrow-right" />
                </span>
              </Link>
              <div className="text">
                <p>Get in Touch</p>
              </div>
            </div>
          </div>
          <div className="hero__btm ">
            <div
              className="cxufadeUp2"
              style={{
                color: "#0a3161",
                fontWeight: "bold",
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                padding: "40px",
                borderRadius: "6px",
              }}
            >
              <p>
                AmeriTrade Data Solutions takes pride in providing data
                solutions across various vertical industries and event types,
                which demonstrates versatility and adaptability.
              </p>
            </div>

            <img src="/assets/imgs/hero/1.png" alt="globe" />
          </div>
        </div>
        <img className="shape-1" src="assets/imgs/shape/10.png" alt="Shape" />
        <img className="shape-2" src="assets/imgs/shape/10.png" alt="Shape" />
        <img
          className="shape-4 cxuSpin-slow"
          src="/assets/imgs/shape/5.png"
          alt="Shape"
        />
        <img className="shape-5" src="/assets/imgs/shape/5.png" alt="Shape" />
      </section>
      {/* Hero area end */}
    </>
  );
};

export default HeroOne;
