import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
const TestimonialOne = () => {
  return (
    <>
      {/* Testimonial area start */}
      <section className="testimonial__area-3 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div className="testimonial__left-3" data-aos="fade-up">
                <h2 className="sec-title text-capitalize text-white">
                  What they’re talking <br /> about us?
                </h2>
                <p>
                AmeriTrade Data Solutions has revolutionized our marketing campaigns with their targeted data solutions. The precision and quality of their data appending services have enabled us to reach our ideal customers effectively. Their team is professional, responsive, and always delivers beyond our expectations.
                </p>
                <Link className="db-btn-border" to="/about">
                  read more <i className="fa-solid fa-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
              <div
                className="testimonial__right-3"
                data-aos="fade-left"
                data-aos-delay={300}
              >
                <div className="swiper testimonial__slider-3">
                  <div className="swiper-wrappers">
                    <Swiper
                      slidesPerView={1}
                      spaceBetween={20}
                      modules={[Autoplay]}
                      autoplay={{
                        delay: 3500,
                        disableOnInteraction: true,
                      }}
                      loop
                    >
                      <SwiperSlide>
                        <div className="testimonial__slide-3">
                          <p>
                          AmeriTrade Data Solutions has revolutionized our marketing campaigns with their targeted data solutions. The precision and quality of their data appending services have enabled us to reach our ideal customers effectively. Their team is professional, responsive, and always delivers beyond our expectations.
                          </p>
                          <div className="testimonial__content-3">
                            <div className="img">
                              <img
                                src="assets/imgs/testimonial/b.jpg"
                                alt="digibold"
                              />
                            </div>
                            <div className="info">
                              <h3 className="client__name-3">Emily Johnson</h3>
                              <h4 className="client__role-3">
                              Marketing Director at HealthPlus Solutions
                              </h4>
                              <ul className="rating">
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="testimonial__slide-3">
                          <p>
                          Partnering with AmeriTrade Data Solutions has been a game-changer for our business. Their data cleansing and validation services have significantly improved our data accuracy, leading to better decision-making and increased operational efficiency. We couldn't be happier with their exceptional service and expertise.
                          </p>
                          <div className="testimonial__content-3">
                            <div className="img">
                              <img
                                src="assets/imgs/testimonial/c.jpg"
                                alt="digibold"
                              />
                            </div>
                            <div className="info">
                              <h3 className="client__name-3">Milo Jasper Reid</h3>
                              <h4 className="client__role-3">
                              CEO of Tech Innovators Inc.
                              </h4>
                              <ul className="rating">
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="testimonial__slide-3">
                          <p>
                          AmeriTrade Data Solutions' expertise in data management has been instrumental in our success. Their lead generation solutions have provided us with high-quality leads that have significantly boosted our sales pipeline. Their dedication to delivering accurate and reliable data is truly commendable.
                          </p>
                          <div className="testimonial__content-3">
                            <div className="img">
                              <img
                                src="assets/imgs/testimonial/a.jpg"
                                alt="digibold"
                              />
                            </div>
                            <div className="info">
                              <h3 className="client__name-3">Sarah Martinez</h3>
                              <h4 className="client__role-3">
                              Senior Analyst at Financial Growth Partners
                              </h4>
                              <ul className="rating">
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                                <li>
                                  <i className="fa-solid fa-star" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Testimonial area end */}
    </>
  );
};

export default TestimonialOne;
