import React from "react";
import { Link } from "react-router-dom";

const ServiceOne = () => {
  return (
    <>
      {/* Service area start */}
      <section className="service__area pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-7 col-xl-7 col-xl-7 col-md-7">
              <div className="service__title-wrap" data-aos="fade-up">
                {/* <h2 className="sec-subtitle">
                  best service <span>best service</span>
                </h2> */}
                <h3 className="sec-title">
                  Innovative solutions for <br /> a digital world
                </h3>
              </div>
            </div>
            <div className="col-xxl-5 col-xl-5 col-xl-5 col-md-5">
              <div
                className="service__text"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <p>
               
                </p>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div className="service__item" data-aos="fade-up">
                <div className="icon">
                  <img src="assets/imgs/icon/s3.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">Pre Event Solutions</div>
                  </Link>
                  <p>
                    Every industry events are unique. This brings us to the big
                    question of how to approach them effectively to generate...
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div
                className="service__item"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/s1.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">During Event Solutions</div>
                  </Link>
                  <p>
                    The number of attendees grow as the show date approaches.
                    Our Updated Data Intelligence Solutions help exhibitor
                    teams...
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div
                className="service__item"
                data-aos="fade-up"
                data-aos-delay={1000}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/s2.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">Post Event Solutions</div>
                  </Link>
                  <p>
                    Post event campaigns are essential as much as Pre-Event
                    Activities and During-Event Activities. We at specialised
                    in...
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-md-6">
              <div
                className="service__item"
                data-aos="fade-up"
                data-aos-delay={1500}
              >
                <div className="icon">
                  <img src="assets/imgs/icon/medal.png" alt="Service Icon" />
                </div>
                <div className="service__content">
                  <Link to="/service">
                    <div className="service__title">Audience Segmentation</div>
                  </Link>
                  <p>
                  AmeriTrade Data Solutions helps to target right audience by segmentation.
                    Maximise customer reach with our...
                  </p>
                  <Link className="db-btn-arrow" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service area end */}
    </>
  );
};

export default ServiceOne;
