import React from "react";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      {/* Service Details area start */}
      <section className="aboutnew__details-area pt-150">
        <div className="container">
          <div className="aboutnew__details top pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/about/a.jpg" alt="digibold" />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="aboutnew__details-title">
                    Trade Show Solutions
                  </h2>
                  <p>
                    AmeriTrade Data Solutions LLC provides comprehensive
                    services for exhibitors to maximize their engagement with
                    prospective customers before, during, and after events. By
                    leveraging business intelligence and data refinement
                    techniques, we help exhibitors identify and connect with
                    their target audience effectively. Offering support for
                    executing mailing and tele-calling campaigns ensures that
                    exhibitors can maintain communication and nurture leads even
                    after the event concludes. This integrated approach is
                    designed to optimize the exhibitor's investment in events
                    and maximize their ROI.
                  </p>
                  <Link className="db-btn-primary btn-rollover" to="/service">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutnew__details mid pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="aboutnew__details-title">
                    Industry Data Solutions
                  </h2>
                  <p>
                    AmeriTrade Data Solutions LLC offers a wide range of
                    solutions to assist clients in discovering new customer
                    opportunities and executing effective direct marketing
                    campaigns. By providing accurate and reliable postal and
                    email lists sourced from trusted sources, we enable clients
                    to connect with their target prospect groups efficiently.
                    Our commitment to affordability and quick response times
                    from both sales and support teams ensures that clients can
                    rely on timely execution of their data and direct marketing
                    projects. This combination of comprehensive services and
                    responsive support is geared towards helping clients achieve
                    their marketing goals effectively and efficiently.
                  </p>
                  <div className="feature">
                    <div className="icon">
                      <img style={{ border: 'none' }} src="assets/imgs/icon/phone.png" alt="Icon" />
                    </div>
                    <div className="info">
                      <h4 className="title">Comprehensive Data Services</h4>
                      <p>
                        Our extensive data services encompass accurate postal
                        and email lists, tailored to meet the specific needs of
                        various industries. Whether you're targeting B2B or B2C
                        audiences, our data solutions ensure precise targeting
                        and higher response rates.
                      </p>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="icon">
                      <img style={{ border: 'none' }} src="assets/imgs/icon/phone.png" alt="Icon" />
                    </div>
                    <div className="info">
                      <h4 className="title">Content Forge</h4>
                      <p>
                        Leverage our content creation services to enhance your
                        marketing campaigns. From crafting engaging emails to
                        developing persuasive sales copy, our expert team helps
                        you communicate effectively with your audience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="assets/imgs/about/b.jpg" alt="Industry Data Solutions" />
                </div>
              </div>
              <div className="col-xxl-12">
                <p>
                  Partner with AmeriTrade Data Solutions LLC to unlock new
                  opportunities for your business. Our data-driven strategies
                  and marketing solutions are designed to provide measurable
                  results, helping you achieve your business objectives
                  efficiently. Contact us today to learn more about how we can
                  support your marketing efforts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service Details area end */}
    </>
  );
};

export default About;
